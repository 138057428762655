import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { TwilioService } from 'services';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSortHeader } from '@angular/material/sort';
import { Record } from '../../models';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})

export class ArchiveComponent implements OnInit {

  displayedColumns: string[] = ['date_created', 'duration', 'participants', 'size', 'container_format', 'download'];
  dataSource: MatTableDataSource<Record>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private twilioService: TwilioService
  ) {

  }

  ngOnInit() {
    this.getRecords();

  }

  getRecords(): void {
    this.twilioService.getRecords().subscribe((data: Record[]) => {
      data.forEach(record => {
        let occurence = this.occurrences(record.participants, "|", true);
        // replace all occurences | to , for valid json
        for (let i = 0; i < occurence; i++) {
          record.participants = record.participants.replace('|', ',');

        }
        // make array of objects then parse
        if (record.participants.charAt(0) === '{') {
          try {
            record.participants = JSON.parse('[' + record.participants + ']');

          } catch (e) {
          }
          // if it comes as other type just rewrite it into this array of objectsx
        } else {
          record.participants = '[{"identity_id":144,"name":"Christine","org_id":1,"user_id":18},{"identity_id":160,"name":"Blake","org_id":1,"user_id":19}]';
          record.participants = JSON.parse(record.participants);
        }

      });
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.sort.active = 'date_created';
      this.sort.direction = 'desc';
      this.sort.sortChange.emit();
      this.sort._stateChanges.next();
    });
  }

  occurrences(string, subString, allowOverlapping) {
    string += "";
    subString += "";
    if (subString.length <= 0) return (string.length + 1);

    let n = 0,
      pos = 0,
      step = allowOverlapping ? 1 : subString.length;

    while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
        ++n;
        pos += step;
      } else break;
    }
    return n;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadMedia(recordId: any): void {
    window.location.href = 'http://api-dev.tacit.io/v2/twilio/recordings/' + recordId;
  }

}
