import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from 'services';

@Injectable( {
  providedIn: 'root'
} )
export class AuthGuard implements CanActivate {

  constructor(
               private sessionService: SessionService,
               private router: Router ) {
  }

  canActivate( route: ActivatedRouteSnapshot,
               state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    if (!!this.sessionService.getCurrentSession()) {
      return true;
    } else {
      console.log('No session found, sending to login ...');
      this.router.navigate(['login'], { queryParams: { from: state.url }});
      return false;
    }
  }
}
