export class Record {
    public account_sid?: string;
    public coded?: string;
    public container_format?: string;
    public date_created?: string;
    public duration?: number;
    public grouping_sids?: GroupingSids;
    public links?: Links;
    public offset?: number;
    public participants?: string;
    public sid?: string;
    public size?: number;
    public source_sid?: string;
    public status?: string;
    public track_name?: string;
    public type?: string;
    public url?: string;
}

export class GroupingSids {
  public participant_sid?: string;
  public room_sid?: string;
}

export class Links {
  public media?: string;
}
