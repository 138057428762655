import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Group, Organization } from 'models';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private resourceUrl = 'https://api-dev.tacit.io/v2/organizations';

  constructor(private http: HttpClient,
              private sessionService: SessionService) {
  }

  /**
   * Get all organizations
   */
  public getAll(): Observable<any> {
    return this.http.get<Organization[]>(this.resourceUrl).pipe(
      map((response) => {
        return response;
      }),
      catchError((e: Error) => {
        console.error('Error occurred: ', e);
        throw e;
      })
    );
  }

  public getAllGroups(): Observable<any> {
    return this.http.get<Group[]>(`${this.resourceUrl}/groups`).pipe(
      map((response) => {
        return response;
      }),
      catchError((e: Error) => {
        console.log('error occured', e);
        throw  e;
      })
    );
  }

  public getGroupById(id: number): Observable<any> {
    return this.http.get<Group>(`${this.resourceUrl}/groups/${id}`).pipe(
      map((response) => {
        return response;
      }),
      catchError((e: Error) => {
        console.log('error ocur ed', e);
        throw e;
      })
    );
  }

  public createGroup(group: Group): Observable<Group> {
    const copy: Group = Object.assign({}, group);
    return this.http.post(`${this.resourceUrl}/groups`, copy).pipe(
      map((response) => {
        return response;
      }),
      catchError((e: Error) => {
        // console.log('error ocured', e);
        throw e;
      })
    );
  }

  public getMyGroups(): Observable<any> {
    return this.http.get(`https://api-dev.tacit.io/v2/users/me/groups`).pipe(
      map((response) => {
        return response;
      }),
      catchError((e: Error) => {
        // console.log('error ocured', e);
        throw e;
      })
    );
  }


  // public updatePrivateGroup(id :number,priv: boolean): Observable<Group> {
  //   const session = this.sessionService.getCurrentSession();
  //   return this.http.put(`${this.resourceUrl}/groups/${id}`, priv,
  //     { headers: { Authorization: 'Bearer: ' + session.auth.auth.access_token.token } }).pipe(
  //       map((response) => {
  //         return response;
  //       }),
  //     catchError((e: Error) => {
  //       console.log('error ocured', e);
  //       throw e;
  //     })
  //   )
  // }
}
