import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../../services';
import { Group } from '../../models';

@Component({
  selector: 'groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})

export class GroupsComponent implements OnInit {

  groups: Group[];
  group: Group;

  constructor(
    private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.group = new Group();
    this.organizationService.getAllGroups().subscribe(data => {
      console.log(data);
      this.groups = data;
    });
    this.organizationService.getGroupById(1).subscribe(group => {
      console.log(group);
    });
  }

  save() {
    this.organizationService.createGroup(this.group).subscribe(response => {
      console.log(response);
    })
  }

  // update() {
  //   this.organizationService.updatePrivateGroup(this.group.id, this.group.private).subscribe( res => {
  //     console.log(res);
  //   })
  // }
}
