import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule,
  MatIconModule,
  MatBadgeModule,
  MatSelectModule,
  MatButtonModule,
  MatDividerModule,
  MatTableModule,
  MatTabsModule,
  MatCheckboxModule,
  MatInputModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatProgressSpinnerModule, MatListModule, MatSortModule, MatSidenavModule, MatChipsModule, MatSnackBarModule,
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatDividerModule,
    MatTooltipModule,
    MatListModule,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    MatSidenavModule,
    MatChipsModule,
    MatSnackBarModule
  ],
  declarations: [],
  exports: [
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatDividerModule,
    MatTooltipModule,
    MatListModule,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    MatSidenavModule,
    MatChipsModule,
    MatSnackBarModule
  ]
})
export class MaterialComponentsModule { }
