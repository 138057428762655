import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { SessionService, UserService, OrganizationService } from 'services';
import { Session, User, Organization } from 'models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginInput: any = { username: null, lastname: null, email: null, password: null, organization_id: null };

  errorMessage: string = null;
  infoMessage: string = null;
  user: any = null;
  organizations: Organization[];
  imagePath = '';
  inProgress = false;
  showSingUpForm = false;

  @ViewChild('username', { static: false }) username;
  @ViewChild('lastname', { static: false }) lastname;
  @ViewChild('email', { static: true }) email;
  @ViewChild('password', { static: true }) password;

  constructor(private sessionService: SessionService,
              private userService: UserService,
              private organizationService: OrganizationService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.organizationService.getAll().subscribe(data => {
      this.organizations = data;
      console.log(data);
    });

  }

  public submit( event?: any ): void {
    let proceed = false;
    this.errorMessage = null;
    this.infoMessage = null;
    this.inProgress = false;
    if ( !!event ) {
      event.preventDefault();
      proceed = event.type !== 'keyup' || event.keyCode === 13;
    }

    if (this.showSingUpForm) {
      this.register(proceed);
    } else {
      this.login(proceed);
    }
  }

  public login( proceed: boolean ): void {
    if (!this.loginInput.email || !this.loginInput.password) {
      this.errorMessage = 'Username and Password are required fields.';
      if (!this.loginInput.email) {
        this.email.nativeElement.focus();
      } else {
        this.password.nativeElement.focus();
      }
    } else if ( proceed ) {
      this.inProgress = true;
      this.sessionService.login(this.loginInput.email, this.loginInput.password).subscribe(( auth: any ) => {
        const session = new Session({ auth: auth });
        sessionStorage.setItem('auth_token', auth.access_token.token);
        this.userService.me().subscribe(( user: User ) => {
          session.user = user;
          this.sessionService.storeSession(session);
          this.inProgress = false;
          this.router.navigateByUrl('/');
        });
      }, ( e: any ) => {
        if (e.status === 404) {
          this.errorMessage = 'Username doesn\'t exist, please try again.';
        } else if (e.status === 400) {
          this.errorMessage = 'Incorect password. Please try again.';
        } else {
          this.errorMessage = 'Unexpected error. Please try again later.';
        }
        this.inProgress = false;
      } );
    }
  }

  public register( proceed: boolean ): void {
    const emptyFields = Object.keys(this.loginInput).filter(i => !this.loginInput[i]);
    if (emptyFields.length) {
      this.errorMessage = 'All fields are required.';
      if (emptyFields[0] !== 'organization_id') {
        this[emptyFields[0]].nativeElement.focus();
      }
    } else if ( proceed ) {
      this.inProgress = true;

      const payload = {
        email: this.loginInput.email,
        password: this.loginInput.password,
        identity: this.loginInput.username + ' ' + this.loginInput.lastname,
        organization_id: this.loginInput.organization_id
      };

      this.userService.register(payload).subscribe(( data: any ) => {
        this.inProgress = false;
        this.toggleSingUpForm();
        this.infoMessage = 'New account has been created';
      }, ( e: any ) => {
        console.log('error: ', e);
        this.inProgress = false;
        this.errorMessage = e.error.error_info;
      } );
    }
  }

  public toggleSingUpForm(): void {
    this.showSingUpForm = !this.showSingUpForm;
    this.errorMessage = null;
  }

}
