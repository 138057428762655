import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { SessionService } from './session.service';
import { User } from 'models';


@Injectable({
  providedIn: 'root'
})
export class TwilioService {

  private currentUser: User;
  private videoUrl = 'https://api-dev.tacit.io/v2/twilio/recordings';
  private streamUrl = 'https://api-dev.tacit.io/v3/twilio/video_rooms';

  constructor(private http: HttpClient, private sessionService: SessionService) {
  }

  /**
   * Get user records
   */
  public getRecords(): Observable<any> {
    const session = this.sessionService.getCurrentSession();
    // debugger;
    return this.http.get<any>('https://api-dev.tacit.io/v2/twilio/recordings').pipe(
      map((response: any) => {
        return response;
      }),
      catchError((e: Error) => {
        console.error('Error occurred: ', e);
        throw e;
      })
    );
  }

  public download(id: number): Observable<any> {
    return this.http.get<any>(`${this.videoUrl}/${id}`, { observe: 'response' }).pipe(
      map((res) => {
        console.log(res);
        return res;
      }),
      catchError((e: Error) => {
        console.error('Error occurred: ', e);
        throw e;
      })
    );
  }

  public getVideoRooms(): Observable<any> {
    return this.http.get<any>(`${this.streamUrl}`, {observe: 'response'}).pipe(
      map((res) => {
        console.log(res);
        return res;
      }),
      catchError((e: Error) => {
        console.error('Error occured:', e);
        throw e;
      })
    );
  }
  public getParticipants(sid): Observable<any> {
    return this.http.get<any>(`${this.streamUrl}/${sid}/participants`, {observe: 'response'}).pipe(
      map((res)=> {
        console.log(res);
        return res;
      }),
      catchError((e: Error) => {
        console.error('Error occured:', e);
        throw e;
      })
    )
  }
}
