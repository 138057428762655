import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';

import { ChatService, OrganizationService, SessionService, TwilioService, UserService } from 'services';
import { Group, User } from 'models';

import { Guid } from 'guid-typescript';
import { Message } from 'twilio-chat/lib/message';
import { Channel } from 'twilio-chat/lib/channel';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { connect, createLocalTracks, createLocalVideoTrack, createRemoteVideoTrack } from 'twilio-video';

export interface DialogData {
  test: 'test'
}

const Video = require('twilio-video');

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  private currentUser: User;
  private group: Group;
  groups: Group[];
  public isGettingChannels: boolean = false;
  public channels: any[] = [];
  public channelObj: any;
  public chatMessage: string;
  public isConnected: boolean = false;
  public isConnecting: boolean = false;
  public guiid: string;
  private conSub: any;
  private disconSub: any;
  public channelName: string;
  public messages: any;
  public membersTyping: any = [];
  public currentChannel: Channel;
  public typeObservable: any;
  public currentUsername: string = 'test';
  public realMessage: Message[];
  public authors: any;
  public error: any;
  public identity: any;
  public openVideo: boolean;
  previewing: boolean;
  msgSubject = new BehaviorSubject("");
  roomObj: any;
  public videoRooms: any;

  @ViewChild('chatElement', { static: false }) chatElement: any;
  @ViewChild('chatDisplay', { static: false }) chatDisplay: any;

  @ViewChild('localVideo', { read: ElementRef, static: false }) localVideo: ElementRef;
  @ViewChild('remoteVideo', { read: ElementRef, static: false }) remoteVideo: ElementRef;

  constructor(private sessionService: SessionService,
              private organizationService: OrganizationService,
              private userService: UserService,
              private chatService: ChatService,
              private twilioService: TwilioService,
              private router: Router,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private ngZone: NgZone
  ) {
    this.guiid = Guid.create().toString();

  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateChatDialog, {});
  }

  ngOnInit() {
    this.openVideo = false;

    const Video = require('twilio-video');

    this.organizationService.getMyGroups().subscribe(data => {
      this.groups = data;
    });
    this.authors = [];
    // Video.connect()

  }

  send() {
    console.log('the chat message', this.chatMessage);
    this.currentChannel.sendMessage(this.chatMessage);

    this.chatMessage = null;
  }

  joinVideo() {

  }

  loadChannel(group) {
    this.userService.getIdentity().subscribe(identity => {
      const identityParse = JSON.parse(identity.identity.toString());
      this.channelName = identityParse.org_id + ':' + group.id;
      this.chatService.getChannel(this.channelName).then((channel) => {
        this.currentChannel = channel;
        this.currentChannel.join()
          .then(r => {
            this.initChannel();
            this.error = null;
          })
          .catch(e => {
            this.error = e.message;
            this.openSnackBar(e, null);
          });
      }).catch(e => {
        this.chatService.createChannel(this.guiid, group.isPrivate, this.channelName).then((channel) => {
        });
        this.openSnackBar(e, null);

      });
    });
    this.twilioService.getVideoRooms().subscribe(res => {
      this.videoRooms = res.body;
      this.videoRooms.forEach(room => {
        room.unique_name = JSON.parse(room.unique_name);
      });
    })
  }

  initChannel() {
    this.currentChannel.getMessages().then(messages => {

      this.messages = messages.items;

      this.messages.forEach(message => {
        message.state.author = JSON.parse(message.state.author);
      })
    });

    this.typeObservable = fromEvent(this.chatElement.nativeElement, 'keyup').subscribe(() => {
      this.typing();
    });

    this.currentChannel.on('messageAdded', (m) => {
      console.log('messages', m);
      this.messages.push(m);
      console.log('the message array', this.messages);
      const el = this.chatDisplay.nativeElement;
      setTimeout(() => {
        el.scrollTop = el.scrollHeight;
      });
    });
    this.currentChannel.on('typingStarted', (m) => {
      this.membersTyping.push(m);
    });
    this.currentChannel.on('typingEnded', (m) => {
      const mIdx = this.membersTyping.findIndex(mem => mem.identity === m.identity);
      this.membersTyping = this.membersTyping.splice(mIdx, 0);
    });

  }

  save() {
    this.organizationService.createGroup(this.group).subscribe(response => {
      console.log(response);
    });
  }

  typing() {
    this.currentChannel.typing();
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('');
  }

  whosTyping() {
    return this.membersTyping.map(m => {
      if (m.identity !== this.currentUsername) {
        return m.identity;
      }
    }).join(', ');
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000,
    });
  }

  connect(group) {
    this.userService.getIdentity().subscribe(identity => {

      this.identity = identity;
      this.chatService.getToken(identity).subscribe(response => {
        this.shareVideo(response, group);
      })

    });
  }



  connectToRoom(accessToken, options): void {

    connect(accessToken.video, options).then(room => {

      room.on('participantConnected', participant => {
        console.log(`Participant "${participant.identity}" connected`);

        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            console.log(publication.track)
            const track = publication.track;
              this.remoteVideo.nativeElement.appendChild(track.attach());
          }
        });

        participant.on('trackSubscribed', track => {
            this.remoteVideo.nativeElement.appendChild(track.attach());
        });
      });

        // participant.on('trackSubscribed', track => {
        //   this.remoteVideo.nativeElement.appendChild(track.attach());
        // });

        // room.participants.forEach(participant => {
        //   this.msgSubject.next("Already in Room: '" + participant.identity + "'");
        //   // console.log("Already in Room: '" + participant.identity + "'");
        //   // this.attachParticipantTracks(participant);
        // });

        // room.on('participantDisconnected', (participant) => {
        //   this.msgSubject.next("Participant '" + participant.identity + "' left the room");
        //   // console.log("Participant '" + participant.identity + "' left the room");
        //
        //   this.detachParticipantTracks(participant);
        // });

        // room.on('participantConnected', (participant) => {
        //   console.log(participant);
        //   participant.tracks.forEach(track => {
        //     this.remoteVideo.nativeElement.appendChild(track.attach());
        //   });
        //
        //   participant.tracks.forEach(publication => {
        //     if (publication.isSubscribed) {
        //       const track = publication.track;
        //       this.remoteVideo.nativeElement.appendChild(track.attach());
        //     }
        //   });
        //
        //   participant('trackSubscribed', track => {
        //     this.remoteVideo.nativeElement.appendChild(track.attach());
        //   });
        //   participant.on('trackSubscribed', track => {
        //     this.remoteVideo.nativeElement.appendChild(track.attach());
        //
        //   });
        //
        //   // room.participants.forEach(function (participant) {
        //   //   console.log('Remote Participant connected: ', participant);
        //   //
        //   //   participant.tracks.forEach(function (publication) {
        //   //     if (publication.isSubscribed) {
        //   //       const track = publication.track;
        //   //       document.getElementById('co-browsing-remote-screen').appendChild(track.attach());
        //   //     }
        //   //   });
        //   // });
        //
        //   participant.on('trackAdded', track => {
        //     console.log('track added')
        //     this.remoteVideo.nativeElement.appendChild(track.attach());
        //     // document.getElementById('remote-media-div').appendChild(track.attach());
        //   });
        // });

        // When a Participant adds a Track, attach it to the DOM.
        // room.on('trackAdded', (track, participant) => {
        //   console.log(participant.identity + " added track: " + track.kind);
        //   this.attachTracks([track]);
        //   participant.tracks.forEach(publication => {
        //     if (publication.isSubscribed) {
        //       const track = publication.track;
        //       this.remoteVideo.nativeElement.appendChild(track.attach());
        //     }
        //   });
        // });

        // When a Participant removes a Track, detach it from the DOM.
        // room.on('trackRemoved', (track, participant) => {
        //   console.log(participant.identity + " removed track: " + track.kind);
        //   this.detachTracks([track]);
        // });
        //
        // room.once('disconnected', room => {
        //   this.msgSubject.next('You left the Room:' + room.name);
        //   room.localParticipant.tracks.forEach(track => {
        //     let attachedElements = track.detach();
        //     attachedElements.forEach(element => element.remove());
        //   });
        // });

        // room.participants.forEach(function (participant) {
        //   console.log('Remote Participant connected: ', participant);
        //
        //   participant.tracks.forEach(function (publication) {
        //     if (publication.isSubscribed) {
        //       const track = publication.track;
        //       document.getElementById('co-browsing-remote-screen').appendChild(track.attach());
        //     }
        //   });
        // });

        // participant.on('trackAdded', track => {
        //   console.log('track added')
        //   this.remoteVideo.nativeElement.appendChild(track.attach());
        //   // document.getElementById('remote-media-div').appendChild(track.attach());
        // });
      });

      // this.roomObj = room;

    // });
  }

  attachParticipantTracks(participant): void {
    let tracks = Array.from(participant.tracks.values());
    this.attachTracks([tracks]);
  }

  attachTracks(tracks) {
    tracks.forEach(track => {
      this.remoteVideo.nativeElement.appendChild(track.attach());
    });
  }

  startLocalVideo(): void {
    console.log(this.localVideo);
    createLocalVideoTrack().then(track => {
      console.log(track);
      this.localVideo.nativeElement.appendChild(track.attach());
    });

  }

  localPreview(): void {
    createLocalVideoTrack().then(track => {
      this.localVideo.nativeElement.appendChild(track.attach());
    });
  }

  detachParticipantTracks(participant) {
    let tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks);
  }

  detachTracks(tracks): void {
    tracks.forEach(track => {
      const attachedElements = track.detach();
      attachedElements.forEach(element => {
        element.remove();
      })
    })
    // tracks.forEach(function (track) {
    //   track.detach().forEach(function (detachedElement) {
    //     detachedElement.remove();
    //   });
    // });
  }

  connectVideoRoom(sid) {
    this.userService.getIdentity().subscribe(identity => {

      this.identity = identity;
      this.chatService.getToken(identity).subscribe(response => {
        this.connectVideo(response, sid);
      })

    });
  }

  connectVideo(token, sid) {
    this.connectToRoom(token, {
      name: {
        group_id: 55,
        identity_id: 2606,
        lat: "48.19",
        long: "17.03",
        name: "andrej hucik",
        org_id: 1,
        t: 1569858673,
        user_id: 107
      },
      video: 240, audio: false })

  }

  shareVideo(token, group) {

    console.log(this.identity);
    // this.connectToRoom(token, { name: {
    //     org_id: 'test'
    //   }, video: 240})

  }

  ngOnDestroy() {
    // this.conSub.unsubscribe();
    // this.disconSub.unsubscribe();
  }

}

@Component({
  selector: 'create-chat-dialog',
  templateUrl: 'create-chat-dialog.component.html',
})

export class CreateChatDialog implements OnInit {

  groups: Group[];
  group: Group;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              private  organizationService: OrganizationService,
              public dialogRef: MatDialogRef<CreateChatDialog>) {

  }

  ngOnInit(): void {
    this.group = new Group();
    this.organizationService.getAllGroups().subscribe(data => {
      console.log(data);
      this.groups = data;
    });
    this.organizationService.getGroupById(1).subscribe(group => {
      console.log(group);
    });
  }

  save() {
    this.organizationService.createGroup(this.group).subscribe(response => {
      console.log(response);
      this.dialogRef.close();
    })
  }
}


