import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/index';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';

import { Session } from 'models';

@Injectable( {
  providedIn: 'root'
} )
export class SessionService {

  static readonly SESSION_KEY = 'tacit_session_key';

  constructor(private http: HttpClient,
              private cookieService: CookieService) { }

  public login(username: string, password: string): Observable<any> {
    const url = 'https://api-dev.tacit.io/v2/auth';
    return this.http.post<any>(url, { email: username, password: password }).pipe(
      map((response: any) => {
        if (response.access_token) {
          return response;
        } else {
          throw new Error('Error');
        }
      }),
      catchError(( e: Error ) => {
        console.error('Error occurred: ', e);
        throw e;
      })
    );
  }

  public storeSession(session: Session) {
    this.cookieService.set(SessionService.SESSION_KEY, JSON.stringify(session));
    console.log('Session has been saved: ', session);
  }

  public getCurrentSession(): Session {
    const sessionValue: string = this.cookieService.get(SessionService.SESSION_KEY);
    if (!!sessionValue) {
      return JSON.parse(sessionValue);
    }
    return null;
  }

  public removeCurrentSession(): void {
    this.cookieService.delete(SessionService.SESSION_KEY);
  }

}
