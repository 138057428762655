import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { appRoutes } from './app.route';

import { MaterialComponentsModule } from 'modules/material-components.module';
import { AuthInterceptorService } from './services';
import { FileSizePipe } from './pipes/file-size.pipe';

import { AppComponent } from 'app.component';
import { ArchiveComponent, CreateChatDialog, LoginComponent, MessagesComponent, SettingsComponent } from 'components';
import { GroupsComponent } from './components/groups/groups.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MessagesComponent,
    ArchiveComponent,
    SettingsComponent,
    GroupsComponent,
    FileSizePipe,
    CreateChatDialog
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialComponentsModule,
    RouterModule.forRoot([...appRoutes])
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  entryComponents: [
    CreateChatDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
