import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent,
         MessagesComponent,
         ArchiveComponent,
         SettingsComponent } from 'components';
import { GroupsComponent } from './components/groups/groups.component';


export const appRoutes: Routes = [
  { path: '', redirectTo: '/archive', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'archive', component: ArchiveComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
  { path: 'messages', component: MessagesComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
  { path: 'settings', component: SettingsComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
  { path: 'groups', component: GroupsComponent, pathMatch: 'full', canActivate: [ AuthGuard ] }

];
