import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Identity, User } from 'models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  /**
   * Get user detail
   */
  public me(): Observable<any> {
    return this.http.get<User>('https://api-dev.tacit.io/v2/users/me').pipe(
      map((response: any) => {
        return new User(response.user);
      }),
      tap(session => console.log('Session has been retrieved: ', session)),
      catchError((e: Error) => {
        console.error('Error occurred: ', e);
        throw e;
      })
    );
  }

  public register(payload: any): Observable<any> {
    const url = 'https://api-dev.tacit.io/v2/users';
    return this.http.post<any>(url, payload).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((e: Error) => {
        console.error('Error occurred: ', e);
        throw e;
      })
    );
  }

  public getIdentity(): Observable<Identity> {
    const url = 'https://api-dev.tacit.io/v2/users/me/identity';

    const deviceId = `Device-${Date.now()}`; // TODO: More random value
    const deviceType = 'PC';

    const params = new HttpParams()
      .set('device_id', deviceId)
      .set('device_type', deviceType);

    return this.http.get<Identity>(url, { params });
  }
}
