import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './services';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Tacit';

  constructor(private sessionService: SessionService,
              public router: Router) { }

  public hasSession(): boolean {
    return !!this.sessionService.getCurrentSession();
  }

  public logout(): void {
    if (confirm('Are you sure you wish to log out?')) {
      this.sessionService.removeCurrentSession();
      this.router.navigate(['login']);
    }
  }
}
