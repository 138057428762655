export class User {
  public id?: number;
  public email?: string;
  public identity?: number;
  public role?: number;
  public organization_id?: number;
  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.email = data.email;
      this.identity = data.identity;
      this.role = data.role;
      this.organization_id = data.organization_id;
    }
  }
}
