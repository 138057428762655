import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpStatus } from '../constants/http-status.constant';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  private static readonly AUTH_TOKE_ATTR: string = 'auth_token';

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken: string = sessionStorage.getItem(AuthInterceptorService.AUTH_TOKE_ATTR);
    if (authToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer: ${authToken}`
        }
      });
    }

    return next.handle(req).pipe(tap(
      () => {},
      this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse): void {
    if (error.status === HttpStatus.UNAUTHORIZED || error.status === HttpStatus.FORBIDDEN) {
      // Remove old expired/invalid token from the storage
      sessionStorage.removeItem('auth_token');
      // Redirect to the login screen
      // noinspection JSIgnoredPromiseFromCall
      this.router.navigate(['/login']);
    }
  }
}
