import { Router } from '@angular/router';
import { ElementRef, EventEmitter, Injectable, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import Client from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';

import { UserService } from './user.service';
import { Identity } from '../models';
import { connect, createLocalTracks, createLocalVideoTrack } from 'twilio-video';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  previewing: boolean;
  msgSubject = new BehaviorSubject("");
  roomObj: any;

  remoteVideo: ElementRef;
  localVideo: ElementRef;

  private chatClient: Client;

  private currentChannel: Channel;

  public chatConnectedEmitter: EventEmitter<any> = new EventEmitter<any>();

  public chatDisconnectedEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient,
              private router: Router,
              private userService: UserService) {
    this.userService.getIdentity().subscribe(this.loadData.bind(this));
  }

  /**
   * Retrieve user chat token (based on auth_token stored in session storage)
   *
   * @param identity
   */
  private loadData(identity: Identity): void {
    const url = `https://api-dev.tacit.io/v3/twilio/tokens/${identity.id}`;

    this.http
      .get<{ chat?: string }>(url)
      .subscribe(this.connectSocket.bind(this), (err: Error) => console.error(err));

    // this.http
    //   .get<{video?: string}>(url)
    //   .subscribe(this.connectVideo.bind(this), (err: Error) => console.error(err));
  }

  public getToken(identity: Identity) {
    const url = `https://api-dev.tacit.io/v3/twilio/tokens/${identity.id}`;
     return this.http
        .get(url)

  }

  /**
   * Connect to the Twilio chat socket client
   *
   * @param token
   */
  private connectSocket(token: { chat?: string }): void {
    Client.create(token.chat)
      .then((client: Client) => this.chatClient = client)
      .catch((err: any) => console.error(err));
  }

  public getPublicChannels() {
    return this.chatClient.getPublicChannelDescriptors();
  }

  public getChannel(uniqueName: string): Promise<Channel> {
    return this.chatClient.getChannelByUniqueName(uniqueName);
  }

  public createChannel(friendlyName: string, isPrivate: boolean, uniqueName: string): Promise<Channel> {
    return this.chatClient.createChannel({ friendlyName: friendlyName, isPrivate: isPrivate, uniqueName: uniqueName });
  }


}
